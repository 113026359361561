p {
  color: #002d36;
  font-size: 1em;
}

hr {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #002d36;
}

img {
  max-width: 100%;
}

.btn {
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #ff900a;
  border-color: #ff900a;
}

.btn-white {
  background: #fff;
  color: #333;
}
.btn-border{
  background: #fff;
  border:1px solid #ff900a;
}

.btn-white:hover,
.btn-white:focus {
  background: #eee;
  color: #333;
}

.btn-danger {
  background-color: #ff900a;
  border-color: #ff900a;
}

a.disabled {
  opacity: 0.7;
  cursor: pointer;
}

.text-primary {
  color: #ff900a;
}

.text-danger {
  color: #ff900a;
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background: transparent;
  border-color: transparent;
  transition: all 0.3s;
}

.navbar-light .navbar-brand {
  color: #fff;
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.navbar-light .navbar-toggler {
  border: 0;
  outline: 0;
  padding: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.3s;
  margin: 0 0.7rem;
}

.navbar-light .navbar-nav .header-cart a.nav-link {
  padding: 0 17px 0 0;
}

.navbar-light .navbar-nav .header-cart a.nav-link:focus,
.navbar-light .navbar-nav .header-cart a.nav-link:active,
.navbar-light .navbar-nav .header-cart a.nav-link:hover {
  border: 0;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-fixed-top.nav-on-scroll {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 100px;
}

.navbar-light .navbar-toggler-icon {
  background-image: none;
  color: #fff;
  line-height: 30px;
}

.navbar-fixed-top.nav-on-scroll .navbar-toggler-icon,
.navbar-fixed-top.nav-on-scroll .navbar-nav .nav-link,
.navbar-fixed-top.nav-on-scroll .navbar-brand {
  color: #002d36;
}

.navbar-fixed-top.nav-on-scroll .navbar-nav .nav-link:focus,
.navbar-fixed-top.nav-on-scroll .navbar-nav .nav-link:active,
.navbar-fixed-top.nav-on-scroll .navbar-nav .nav-link:hover {
  color: #ff900a;
}

.navbar-collapse.navbar-right {
  background-color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #002d36;
}

.navbar-fixed-top.nav-on-scroll .navbar-nav .nav-item.active .nav-link {
  color: #002d36;
  border-bottom: 1px solid #002d36;
}

.navbar-fixed-top .navbar-nav .nav-item.active .nav-link {
  border-bottom: 1px solid #002d36;
}

.navbar-fixed-top .navbar-nav .nav-link:focus,
.navbar-fixed-top.navbar-nav .nav-link:active,
.navbar-fixed-top .navbar-nav .nav-link:hover {
  color: #ff900a;
  border-bottom: 1px solid #002d36;
}

@media (min-width: 992px) {
  .navbar-fixed-top .navbar-nav .nav-link:focus,
  .navbar-fixed-top.navbar-nav .nav-link:active,
  .navbar-fixed-top .navbar-nav .nav-link:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: #fff;
  }
  .collapse.navbar-collapse.navbar-right {
    display: inline-block !important;
  }
  .navbar-right .navbar-nav {
    float: right;
  }
  .navbar-collapse.navbar-right {
    background-color: transparent;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #fff;
  }
  .navbar-light .navbar-nav .nav-item.active .nav-link {
    border-bottom: 1px solid #fff;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 0;
  }
}

.main-wrapper .hero-banner {
  min-height: 80vh;
  background-image: linear-gradient(#37659b, #ff900a);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-wrapper .main-banner {
  min-height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.main-wrapper .hero-banner.shop-hero-banner {
  background-color: #37659b;
  background-image: none;
}

.main-wrapper .hero-banner .back-decorate {
  height: 100vh;
  background: url("./img/marketing/back-decorate.png") no-repeat center bottom;
  background-size: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.main-wrapper .hero-banner .hero-silhouette {
  background: url("./img/marketing/silhouette.png") no-repeat bottom center;
  position: absolute;
  left: 0;
  right: 0;
  height: 275px;
  background-size: contain;
  bottom: -10px;
  z-index: 1;
}

.punch-line {
  text-align: center;
  padding: 50px 0;
  background: #fff;
  position: relative;
}

.punch-line h2 {
  font-size: 2.3rem;
  font-weight: 700;
  color: #002d36;
}

@media (min-width: 992px) {
  .punch-line h2 {
    font-size: 3rem;
    font-weight: 700;
  }
  .punch-line {
    padding: 70px 0;
  }
}

@media (min-width: 1025px) {
  .main-wrapper .hero-banner {
    min-height: 100vh;
  }
}

.punch-line p {
  font-size: 1.4rem;
}

.main-wrapper .hero-banner .hero-banner-content {
  margin-top: 50px;
  margin-bottom: 150px;
  height: 100%;
  z-index: 10;
  position: relative;
  text-align: left;
}

.main-wrapper .hero-banner .hero-banner-content .phone-wrapper {
  text-align: center;
  margin-top: 40px;
}

.main-wrapper .hero-banner .hero-banner-content .phone-wrapper img {
  max-width: 280px;
}

.main-wrapper .hero-banner .hero-banner-content .hero-banner-text {
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
}

.main-wrapper .hero-banner .hero-banner-content .hero-banner-text h1 {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  text-shadow: 0 6px 0px rgba(0, 0, 0, 0.28);
}

.main-wrapper .hero-banner .hero-banner-content .hero-banner-text p {
  color: #fff;
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .main-wrapper .hero-banner .hero-banner-content .phone-wrapper img {
    max-width: 100%;
  }
  .main-wrapper .hero-banner .hero-banner-content .phone-wrapper {
    margin-top: 0;
  }
  .main-wrapper .hero-banner .hero-banner-content {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .main-wrapper .hero-banner .hero-banner-content .hero-banner-text h1 {
    font-size: 4rem;
    font-weight: 800;
  }
  .main-wrapper .hero-banner .hero-banner-content .hero-banner-text p {
    font-size: 1.7rem;
  }
  .main-wrapper .hero-banner .hero-banner-content ul.hero-buttons li {
    margin-bottom: 0;
  }
}

.main-wrapper .hero-banner .hero-banner-content ul.hero-buttons {
  list-style-type: none;
  padding-left: 0;
}

.main-wrapper .hero-banner .hero-banner-content ul.hero-buttons li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.main-wrapper .hero-banner .hero-banner-content ul.hero-buttons li .btn {
  border-radius: 50px;
  padding: 12px 30px;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.feature-box {
  background-color: #fff;
  position: relative;
}

.feature-box .border-card {
  text-align: center;
  border: 1px solid #eee;
  padding: 50px 40px;
  height: auto;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .feature-box .border-card {
    margin-bottom: 0;
    height: 100%;
  }
}

.feature-box .border-card h3 {
  font-weight: 700;
  color: #002d36;
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-top: 25px;
}

.feature-box .border-card figure {
  width: 65px;
  height: 65px;
  margin: 0 auto;
}

.feature-box .border-card figure img {
  max-width: 100%;
  max-height: 100%;
}

.feature-box .border-card p {
  margin-bottom: 0;
}

.showcase-wrapper {
  background-color: #fff;
  position: relative;
  padding: 40px 0 70px;
}

.showcase-wrapper .showcase-content {
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
}

.showcase-wrapper .showcase-content h2 {
  font-weight: 600;
  font-size: 2rem;
}

.showcase-wrapper .showcase-content p {
  margin-bottom: 0;
}

.showcase-wrapper.showcase-left {
  padding-bottom: 20px;
}

.showcase-wrapper .showcase-phone {
  text-align: center;
}

.showcase-wrapper .showcase-phone img {
  max-width: 350px;
}

.showcase-content {
  text-align: center;
}

.showcase-wrapper.showcase-left .showcase-content {
  padding-top: 30px;
}

.showcase-wrapper.showcase-right .showcase-content {
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .showcase-wrapper .showcase-content h2 {
    font-size: 2.8rem;
  }
  .showcase-wrapper {
    padding-bottom: 0;
  }
  .showcase-wrapper.showcase-left {
    padding-bottom: 0;
  }
  .showcase-wrapper.showcase-right {
    padding-top: 0;
    padding-bottom: 90px;
  }
  .showcase-wrapper.showcase-left .showcase-phone {
    text-align: left;
  }
  .showcase-wrapper.showcase-right .showcase-phone {
    text-align: right;
  }
  .showcase-phone img {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .showcase-content {
    text-align: left;
  }
  .showcase-wrapper.showcase-left .showcase-content {
    padding-top: 0;
  }
  .showcase-wrapper.showcase-right .showcase-content {
    padding-bottom: 0;
  }
}

.testimonial-wrapper {
  background-image: linear-gradient(#ff900a, #ff900a);
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  padding: 80px 0;
}

.testimonial-wrapper .testi-bg {
  background: url("./img/marketing/testi-bg.png") no-repeat center top;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
}

.testimonial-wrapper .quote-fig {
  text-align: center;
  margin-bottom: 20px;
}

.testimonial-wrapper .quote-fig img {
  width: 40px;
}

.testimonial-wrapper .quote-slider-wrapper {
  position: relative;
  z-index: 1;
}

.testimonial-wrapper h3,
.testimonial-wrapper h4,
.testimonial-wrapper p {
  color: #fff;
}

.testimonial-wrapper h3 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.testimonial-wrapper h4 {
  font-weight: 700;
  font-size: 1.3rem;
}

.testimonial-wrapper .bx-wrapper {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 0;
}

.testimonial-wrapper .avatar-pager {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.testimonial-wrapper .avatar-pager li {
  display: inline-block;
  margin: 0 5px;
}

.testimonial-wrapper .avatar-pager li a {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #fff;
  opacity: 0.4;
  overflow: hidden;
  border-radius: 100px;
}

.testimonial-wrapper .avatar-pager li a img {
  max-width: 100%;
}

.testimonial-wrapper .avatar-pager li a.active {
  opacity: 1;
}

.product-slider-box {
  padding: 70px 0;
  display: block;
  position: relative;
  background-color: #fff;
}

.product-slider-box .product-actions {
  display: block;
}

.product-slider-box .product-actions .form-control {
  text-align: center;
  border: 0;
  width: 50px;
  font-weight: 700;
  font-size: 1.7rem;
  padding: 0;
  line-height: 0;
  box-shadow: 0 0 0 transparent;
}

.product-slider-box .product-slider .product-actions .btn-buy {
  width: 150px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 41px;
  box-shadow: 0 8px 15px rgba(65, 100, 151, 0.4);
  margin: 20px 0;
}

.product-slider-box
  .product-slider
  .product-slider-item
  .product-slider-content
  h4 {
  color: #ff900a;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 15px;
}

.product-slider-box
  .product-slider
  .product-slider-item
  .product-slider-content
  h3 {
  font-weight: 600;
}

.product-slider-box
  .product-slider
  .product-slider-item
  .product-slider-content
  p.price-tag {
  font-weight: 800;
  font-size: 1.75rem;
}

.product-slider-box .product-slider .product-actions .quantity-wrapper p {
  line-height: 22px;
  color: #777;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

.product-slider-box .product-slider .product-actions .quantity-wrapper .btn {
  outline: 0 !important;
  box-shadow: 0 0 0 transparent !important;
  font-size: 0.8rem;
  color: #777;
  border-radius: 0;
  background: #fff;
}

.product-slider-box
  .product-slider
  .product-slider-item
  .product-slider-content {
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.product-slider-box .bx-wrapper {
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 0;
}

.product-slider-box .bx-wrapper .bx-pager.bx-default-pager {
  padding-top: 0;
  bottom: -10px;
}

.product-slider-box .bx-wrapper .bx-pager.bx-default-pager a {
  background-color: transparent;
  border: 1px solid #777;
}

.product-slider-box .bx-wrapper .bx-pager.bx-default-pager a.active {
  background-color: #444;
}

.product-slider-box .bx-wrapper .bx-viewport {
  padding-bottom: 20px;
}

@media (min-width: 576px) {
  .product-slider-box
    .product-slider
    .product-slider-item
    .product-slider-content {
    text-align: left;
  }
  .product-slider-box .product-actions {
    display: flex;
  }
  .product-slider-box .product-slider .product-actions .quantity-wrapper {
    margin-left: 20px;
    margin-top: 20px;
  }
  .product-slider-box
    .product-slider
    .product-slider-item
    .product-slider-content {
    margin-left: 5%;
  }
  .product-slider-box .product-slider .product-actions .btn-buy {
    margin-bottom: 0;
  }
  .product-slider-box .product-slider .product-actions .quantity-wrapper p {
    margin: 0;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#container {
  min-height: 100%;
  position: relative;
}

#header {
  background: #ff0;
}

#body {
  padding-bottom: 150px;
}

.footer {
  background: #28446e url("./img/marketing/foot-silhouette.png") center bottom
    no-repeat;
  background-size: 100%;
}

.footer a,
.footer p {
  color: #fff;
}

.footer .footer-widget-wrapper {
  padding: 60px 20px 0;
}

.footer .footer-bottom {
  text-align: center;
  padding: 20px 10px;
}

.footer .footer-bottom p {
  margin: 0;
}

.footer .footer-widget-wrapper h5 {
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}

.footer .footer-widget-wrapper ul {
  padding-left: 0;
  list-style-type: none;
}

.footer .footer-widget-wrapper ul li a {
  padding: 2px 0;
  display: block;
}

.footer .icon-social {
  border-radius: 50px;
  display: inline-block !important;
  padding: 8px 0 !important;
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 20px;
  margin-right: 8px;
  background-color: #fff;
}

.footer .icon-social.icon-facebook {
  color: #32569f;
}

.footer .icon-social.icon-twitter {
  color: #009ffd;
}

.footer .icon-social.icon-instagram {
  color: #cb4f50;
}

.footer .icon-social.icon-linkedin {
  color: #0e76a8;
}

.subscribe-box {
  display: flex;
}

.subscribe-box .form-control {
  border-radius: 50px;
  border: 0;
  height: 50px;
  padding: 0 1.5rem;
}

.subscribe-box .btn {
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 10px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.footer .widget-box {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .footer .widget-box {
    margin-bottom: 0;
  }
}

.fab-box {
  position: fixed;
  width: 210px;
  bottom: 0;
  z-index: 15;
  right: 10px;
}

.fab-box a {
  color: #fff;
}

.fab-box-button {
  float: right;
  padding: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.fab-buttons .fab-link {
  color: #444;
  background: #fff;
  padding: 2px 10px;
  border-radius: 3px;
  margin-right: 12px;
  top: 8px;
  position: relative;
}

.fab-buttons a {
  display: block;
  clear: both;
  text-align: right;
  text-decoration: none;
  border-bottom: none !important;
}

.fab-buttons a:hover span {
  color: #ff900a;
}

.fab-buttons .fab-buttons__item {
  margin: 40px 0;
}

@media (min-width: 1400px) {
  .fab-box__button {
    margin-right: 50px;
  }
  .fab-box__content {
    padding: 20px 200px;
  }
}

.at-button {
  position: absolute;
  right: 24px;
  bottom: 24px;
  background: #37659b;
  width: 48px;
  height: 48px;
  padding: 11px 0;
  z-index: 15;
  border-radius: 100%;
  box-sizing: border-box;
  color: #666;
  -webkit-animation: at-ripple 0.6s linear infinite;
  animation: at-ripple 0.6s linear infinite;
  text-align: center;
}

.at-button i {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

@-webkit-keyframes at-ripple {
  0% {
    box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1),
      0 0 0 0 rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1),
      0 0 0 10px rgba(102, 102, 102, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1),
      0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1),
      0 0 0 20px rgba(102, 102, 102, 0);
  }
}

@keyframes at-ripple {
  0% {
    box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1),
      0 0 0 0 rgba(102, 102, 102, 0.1), 0 0 0 5px rgba(102, 102, 102, 0.1),
      0 0 0 10px rgba(102, 102, 102, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(102, 102, 102, 0.1),
      0 0 0 5px rgba(102, 102, 102, 0.1), 0 0 0 10px rgba(102, 102, 102, 0.1),
      0 0 0 20px rgba(102, 102, 102, 0);
  }
}

.at-button svg {
  position: absolute;
}

.at-button .svg-cross {
  opacity: 0;
  transition: opacity 0.25s;
}

.at-button .svg-question-mark {
  opacity: 1;
  transition: opacity 0.25s;
}

.toggle-icon.at-button .svg-question-mark {
  opacity: 0;
}

.toggle-icon.at-button .svg-cross {
  opacity: 1;
}

a.at-button.toggle-icon {
  bottom: -20px;
}

.fab-box:hover .fab-buttons {
  opacity: 1;
  visibility: visible;
}

.fab-box .fab-buttons__link {
  color: #444;
}

.fab-action-button {
  position: absolute;
  bottom: 0;
  display: block;
  width: 56px;
  height: 56px;
  background-color: #29b6f6;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.fab-buttons {
  position: absolute;
  left: 0;
  right: 30px;
  bottom: 80px;
  list-style: none;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}

.show-message {
  background: rgba(0, 0, 0, 0.05);
  border-left: 1px solid #ff4e4e;
  padding: 9px;
  margin-bottom: 10px;
  font-weight: bold;
}

.fab-buttons__link {
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  float: right;
  padding: 8px 0;
}

.icon-material {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.icon-material_fb {
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0icG9zdC1mYWNlYm9vayI+CgkJPHBhdGggZD0iTTQ1OSwwSDUxQzIyLjk1LDAsMCwyMi45NSwwLDUxdjQwOGMwLDI4LjA1LDIyLjk1LDUxLDUxLDUxaDQwOGMyOC4wNSwwLDUxLTIyLjk1LDUxLTUxVjUxQzUxMCwyMi45NSw0ODcuMDUsMCw0NTksMHogICAgIE00MzMuNSw1MXY3Ni41aC01MWMtMTUuMywwLTI1LjUsMTAuMi0yNS41LDI1LjV2NTFoNzYuNXY3Ni41SDM1N1Y0NTloLTc2LjVWMjgwLjVoLTUxVjIwNGg1MXYtNjMuNzUgICAgQzI4MC41LDkxLjgsMzIxLjMsNTEsMzY5Ljc1LDUxSDQzMy41eiIgZmlsbD0iIzc1NzU3NSIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=")
    center no-repeat;
}

.section-pd {
  padding: 30px 0;
}

.mb-30 {
  margin-bottom: 30px;
}

.shop-showcase {
  background: #fff;
  position: relative;
}

.showcase-title {
  margin-bottom: 10px;
}

.showcase-title a {
  display: block;
  text-decoration: none;
}

.showcase-title .shop-logo {
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
  float: left;
  background: #fff;
}

.showcase-title h4 {
  font-weight: 600;
  float: left;
}

.next-icon img {
  margin-left: 30px;
  margin-top: 13px;
  width: 15px;
}

.showcase-title h4 small {
  color: #999;
  font-size: 15px;
  display: block;
  margin-top: 5px;
}

.product-wrapper {
  text-align: center;
}

.product-wrapper .product-card {
  text-decoration: none;
  color: #333;
  margin: 30px 0;
  display: inline-block;
}

.product-card .product-img {
  margin-bottom: 10px;
  overflow: hidden;
}

.product-card .product-img img {
  -webkit-transition: 0.3s all ease-in;
  -moz-transition: 0.3s all ease-in;
  -ms-transition: 0.3s all ease-in;
  -o-transition: 0.3s all ease-in;
  transition: 0.3s all ease-in;
}

.product-wrapper .product-card:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.product-card .product-summary h5 {
  font-size: 16px;
}

.product-wrapper .col {
  flex: 0 0 20%;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .product-wrapper .col {
    flex: 0 0 33.33%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .product-wrapper .col {
    flex: 0 0 33.33%;
    margin-bottom: 20px;
  }
  .product-wrapper .row {
    justify-content: center;
  }
}

@media (max-width: 493px) {
  .product-wrapper .col {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

.shops-list {
  background: #f9f9f9;
  position: relative;
  padding: 50px 0;
}

.shops-list h3 {
  margin-bottom: 30px;
  font-weight: 600;
}

.shops-list .btn {
  margin-top: 40px;
}

.more-shops-card {
  border-right: 1px solid #ddd;
  background: #fff;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: -1px 1px 5px #ddd;
  padding: 20px;
  display: inline-block;
  text-align: center;
  min-height: 216px;
  margin-bottom: 20px;
  color: #333;
  -webkit-transition: 0.3s all ease-out;
  -moz-transition: 0.3s all ease-out;
  -ms-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}

.more-shops-card:hover {
  text-decoration: none;
  box-shadow: -1px 1px 10px #ccc;
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: 0.5s all ease-in;
  -moz-transition: 0.5s all ease-in;
  transition: 0.5s all ease-in;
}

.more-shops-card img {
  margin-bottom: 10px;
}

.more-shops-card h5 {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.more-shops-card small {
  display: inline-block;
  font-size: 14px;
  color: #999;
}

.view-all .btn {
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
}

.shop-hero-banner .hero-banner-slider .hero-banner-text h1 {
  margin-bottom: 30px;
}

.shop-hero-banner .hero-banner-content .bx-wrapper {
  box-shadow: none;
  border: none;
  background: transparent;
}

.shop-hero-banner
  .hero-banner-content
  .bx-wrapper
  .bx-pager.bx-default-pager
  a {
  background-color: #fff;
}

.shop-hero-banner
  .hero-banner-content
  .bx-wrapper
  .bx-pager.bx-default-pager
  a:hover,
.hero-banner-content .bx-wrapper .bx-pager.bx-default-pager a.active,
.hero-banner-content .bx-wrapper .bx-pager.bx-default-pager a:focus {
  background-color: #fc7f77;
}

.shop-hero-banner .hero-banner-slider-item .hero-banner-text {
  padding: 105px 0 0;
}

@media (max-width: 768px) {
  .shop-hero-banner .hero-banner-slider-item .hero-banner-text {
    padding: 50px 0 0;
    text-align: center;
  }
  .shop-hero-banner .hero-banner-slider-item .hero-banner-product img {
    max-width: 450px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .shop-hero-banner .hero-banner-slider-item .hero-banner-text {
    width: 70%;
    margin: 20px auto 0 auto;
  }
}

.shop-banner-slider {
  padding: 0 20px;
  margin-top: 50px;
  position: relative;
  z-index: 100;
}

.shop-banner-slider .bx-wrapper {
  background: none;
  border: none;
  box-shadow: none;
}

.shop-banner-slider .bx-wrapper .bx-pager.bx-default-pager a {
  background-color: #fff;
}

.shop-banner-slider .bx-wrapper .bx-pager.bx-default-pager a:hover,
.shop-banner-slider .bx-wrapper .bx-pager.bx-default-pager a.active,
.shop-banner-slider .bx-wrapper .bx-pager.bx-default-pager a:focus {
  background-color: #fc7f77;
}

.shop-banner-slider .bx-wrapper .bx-pager,
.shop-banner-slider .bx-wrapper .bx-controls-auto {
  bottom: 30px;
}

.header-cart .nav-link {
  position: relative;
}

.header-cart img {
  margin-top: -5px;
}

.header-cart .cart-count {
  position: absolute;
  top: 4px;
  right: 4px;
  background: #fc7f77;
  width: 15px;
  height: 15px;
  font-size: 11px;
  color: #fff;
  padding: 0;
  line-height: 15px;
  text-align: center;
  border-radius: 100%;
  z-index: 99;
}

.navbar-fixed-top.nav-on-scroll .navbar-nav .nav-link img {
  display: none;
}

.header-cart .nav-link {
  display: inline-block;
  width: auto;
}

.main-wrapper .hero-banner.shop-detail-banner {
  min-height: auto;
  height: auto;
}

.main-wrapper .hero-banner.shop-detail-banner .shop-banner-slider {
  padding: 0 15px;
  margin-top: 65px;
  position: relative;
  z-index: 100;
}

.product-category-tab .showcase-title {
  margin-bottom: 0;
  display: inline-flex;
}

.main-wrapper .hero-banner.shop-detail-banner .shop-banner-slider .bx-wrapper {
  margin-bottom: 0;
}

.main-wrapper .hero-banner.shop-detail-banner .product-category-tab {
  width: 100%;
  display: flex;
  padding: 25px 15px 0;
  position: relative;
  background-color: #416597;
}

.main-wrapper .hero-banner.shop-detail-banner .category-list {
  display: flex;
  list-style: none;
  margin: 0 0 0 50px;
  padding: 0;
  text-align: center;
}

.main-wrapper .hero-banner.shop-detail-banner .category-list li {
  margin-right: 30px;
}

.main-wrapper .hero-banner.shop-detail-banner .category-list li a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 0 10px;
}

.main-wrapper .hero-banner.shop-detail-banner .category-list li.active a {
  border-bottom: 3px solid #ff4e4e;
  font-weight: 700;
}

.main-wrapper .hero-banner.shop-detail-banner .category-list li a img {
  width: 30px;
}

.main-wrapper .hero-banner.shop-detail-banner .category-list li a span {
  display: block;
  padding: 10px 0;
}

.main-wrapper
  .hero-banner.shop-detail-banner
  .product-category-tab
  .showcase-title
  h4 {
  color: #fff;
  line-height: 50px;
}

.page-product-detail .navbar-fixed-top.nav-on-scroll {
  background-color: #fff;
}

.page-product-detail .navbar {
  background-color: #37659b;
}

.page-product-detail .main-wrapper {
  padding-top: 62px;
}

.product-detail {
  padding: 50px 0;
}

.no-padding {
  padding: 0;
}

.preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.no-bg {
  background-image: none !important;
}

.form-control.no-bg {
  padding: 0.375rem 0.75rem !important;
}

.no-border,
.was-validated .form-control.no-border:valid,
.was-validated .form-control.no-border:is-valid {
  border-color: #ced4da !important;
}

.swal-icon--error__line {
  background-color: #fff;
}

.swal-icon--error {
  background-color: #ff900a;
  border-color: #ff900a;
}

.swal-footer {
  text-align: center;
}

.swal-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #002d36;
  margin-top: 20px;
  margin-bottom: 25px;
}

.swal-button {
  background-color: #ff900a;
  border-color: #ff900a;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.swal-button:active,
.swal-button:hover,
.swal-button:not([disabled]):hover {
  background-color: #f35851;
  border-color: #f35851;
}

.swal-text {
  font-size: 14px;
  color: #002d36;
}

@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px;
  }
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

@media (max-width: 640px) {
  .preview .col-sm-2 {
    flex: 0 0 20%;
  }
}

.preview-thumbnail.nav-tabs {
  border: none;
}

.preview-thumbnail.nav-tabs li {
  margin-bottom: 20px;
  border: 1px solid #eee;
}

.preview-thumbnail.nav-tabs li img {
  max-width: 100%;
  display: block;
  cursor: pointer;
}

.preview-thumbnail.nav-tabs li a {
  padding: 0;
  margin: 0;
}

.preview-thumbnail.nav-tabs li:last-of-type {
  margin-right: 0;
}

.tab-content {
  overflow: hidden;
}

.tab-content img {
  width: 100%;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@media screen and (min-width: 997px) {
  .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.checked,
.price span {
  color: #ff9f1a;
}

.product-title,
.rating {
  margin-bottom: 15px;
}

.product-title {
  font-weight: 700;
}

.stars {
  display: inline-block;
  background: #b5cb17;
  padding: 0 10px;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  height: 20px;
}

.offer {
  display: inline-block;
  margin-left: 0px;
  color: #666;
  font-size: 12px;
  padding: 0 10px;
  background: #eee;
  border-radius: 30px;
  line-height: 20px;
  height: 20px;
}

.price {
  margin-bottom: 0;
  font-weight: 700;
}

.price del {
  font-size: 15px;
  margin-left: 20px;
  color: #999;
}

.price-button-wrp {
  display: flex;
  justify-content: space-between;
}

.btn-wrp {
  display: -webkit-flex;
  display: flex;
}

.circle-btn {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  display: inline-block;
  background: #fff;
  text-align: center;
  color: #333;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.circle-btn:hover {
  background: #eee;
}

.rating-offer-wrp {
  margin-bottom: 20px;
}

.sizes,
.colors,
.quantity-wrapper {
  font-weight: 700;
  text-transform: uppercase;
}

.size {
  margin-right: 10px;
  border: 1px solid #999;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  min-width: 30px;
  display: inline-block;
  text-decoration: none;
  color: #666;
}

.size:focus,
.size:hover {
  border-color: #ff900a;
  color: #ff900a;
  text-decoration: none;
}

.size:first-of-type,
.color:first-of-type,
.details .quantity-wrapper .input-group {
  margin-left: 40px;
}

.quantity-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

.quantity-wrapper .input-group {
  width: 143px;
}

.quantity-wrapper .input-group .btn {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  font-size: 12px;
  background: #fff;
}

.quantity-wrapper .input-group .btn:hover {
  background: #f9f9f9;
}

.quantity-wrapper .input-group .form-control {
  margin: 0 15px;
  border: none;
  text-align: center;
}

.quantity-wrapper .input-group > .input-group-prepend:not(:first-child) > .btn {
  border-radius: 100%;
}

.color {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  padding: 5px;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  cursor: pointer;
  border: 3px solid transparent;
}

.colors .color:hover,
.colors .color:focus {
  box-shadow: 0px 0px 0px 2px #ccc;
}

.color:first-of-type {
  margin-left: 20px;
}

.btn-action .btn {
  border-radius: 50px;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.orange {
  background: #ff9f1a;
}

.green {
  background: #85ad00;
}

.blue {
  background: #0076ad;
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.product-description {
  margin-top: 50px;
  border: 1px solid #eee;
  width: 100%;
}

.product-description .nav-tabs .nav-item .nav-link {
  color: #999;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 20px;
}

.product-description .nav-tabs .nav-item.show .nav-link,
.product-description .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border: none;
  font-weight: 700;
  position: relative;
}

.product-description .nav-tabs .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #ff900a;
  left: 0;
}

.product-description .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

@media (max-width: 600px) {
  .nav-tabs .nav-item {
    width: 100%;
    border-bottom: 1px solid #eee;
  }
}

.product-description .tab-pane {
  padding: 20px;
}

.product-description .tab-pane .feature-list {
  margin-left: 0;
  padding-left: 20px;
}

.product-description .tab-pane p {
  font-size: 1rem;
}

.product-description .tab-pane h5 {
  font-weight: 600;
}

.breadcrumb {
  background: none;
  margin: 0;
}

.product-detail .breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 30px;
}

.breadcrumb .breadcrumb-item a {
  color: #ff900a;
  text-decoration: none;
}

.breadcrumb .breadcrumb-item a:hover {
  color: #e7544d;
  text-decoration: none;
}

.more-products {
  border: 1px solid #eee;
  margin-top: 50px;
  padding: 20px;
}

.more-products .showcase-title h4 {
  line-height: 50px;
  margin-right: 10px;
  font-size: 20px;
}

.social-share .social {
  width: 37px;
  height: 37px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  color: #333;
}

.social-share span.social {
  margin-right: 10px;
  text-decoration: none;
  color: #333;
  width: auto;
  background: no-repeat;
  line-height: 37px;
}

.social-share a.social {
  color: #fff;
  border-radius: 50px;
  text-align: center;
  padding: 7px;
}

.social-share a.social:hover {
  opacity: 0.8;
}

.social-share .dropdown-menu {
  padding: 10px 20px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  border: none;
  min-width: 275px;
  left: auto !important;
  right: 0;
  transform: none !important;
  top: 102% !important;
}

.social-share .social.facebook {
  background-color: #32569f;
}

.social-share .social.twitter {
  background-color: #009ffd;
}

.social-share .social.pinterest {
  background-color: #c9232c;
}

.social-share .social.google-plus {
  background-color: #d34736;
}

.review-section {
  border: 1px solid #eee;
  padding: 30px;
  margin-top: 40px;
}

.rating-box,
.review-box {
  text-align: center;
  padding: 30px;
}

.rating-box h1 {
  font-weight: 900;
  display: inline-block;
}

.rating-box h1 span {
  font-size: 18px;
  font-weight: 700;
  color: #888;
}

.review-box {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

@media (max-width: 640px) {
  .review-box {
    border: none;
  }
}

.big-star {
  color: #ffc315;
  font-size: 40px;
  margin-left: 5px;
}

.review-box .btn {
  text-transform: uppercase;
  font-weight: 700;
}

.review-des-box p span {
  color: #ccc;
  display: block;
}

.comment-section {
  border: 1px solid #eee;
  margin-top: 40px;
  background: #fff;
}

.box-header {
  border-bottom: 1px solid #eee;
  padding: 15px 25px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}

.select-option {
  color: #777;
  border-radius: 30px;
  border: 1px solid #eeeeee;
  padding: 5px;
  margin-left: 10px;
}

.sort-by {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.stars-list {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
  display: flex;
  display: -webkit-box;
}

.stars-list li {
  margin-right: 5px;
  color: #ffc315;
}

.comment-list {
  list-style: none;
  margin: 0;
  padding: 25px;
}

.comment-list > li {
  display: flex;
  display: -webkit-flex;
  margin-bottom: 15px;
}

.img-thumb {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.comment-des {
  padding-left: 40px;
}

.authour {
  color: #888;
  font-size: 14px;
}

.qa-section {
  border: 1px solid #eee;
  margin-top: 40px;
}

.search-field {
  background: #eee;
  color: #666;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  box-shadow: none;
  border: none;
}

.box-view-wrp {
  padding: 15px;
}

.qa-list {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}

.qa-list > li {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.qa-list > li:last-child {
  border-bottom: none;
}

.answer-list {
  list-style: none;
}

.highlighter {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 20px;
}

.answer-list li p {
  font-size: 16px;
}

.answer-field {
  padding: 5px 15px;
  color: #666;
  border-radius: 30px;
  border: 1px solid #eee;
  font-size: 15px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.answer-field:focus {
  outline: none;
  background: #f9f9f9;
}

.footer-top {
  background: #2f5083;
  padding: 80px 0;
  color: #fff;
  text-align: center;
}

.footer-top h5 {
  color: #fff;
  margin-top: 20px;
  font-weight: 600;
}

.footer-top p {
  color: #fff;
}

@media (max-width: 640px) {
  .footer-top [class*=" col-"] {
    margin-bottom: 20px;
  }
}

.review-modal input[type="radio"] {
  display: none;
}

.review-modal label {
  color: grey;
}

.review-modal .clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
}

.review-modal label:hover,
.review-modal label:hover ~ label {
  color: orange;
  cursor: pointer;
}

.review-modal input[type="radio"]:checked ~ label {
  color: orange;
}

.review-modal .modal-body {
  text-align: center;
  padding: 0 15px;
}

.review-modal .modal-body textarea {
  color: #888;
  border: 1px solid #eee;
  width: 100%;
  resize: none;
  padding: 10px;
}

.review-modal .modal-body textarea:focus {
  outline: none;
}

.review-modal .modal-header {
  border-bottom: none;
  padding: 10px 10px 0 10px;
}

.review-modal .modal-sm {
  max-width: 350px;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
  padding: 20px 0;
  background: rgba(47, 80, 131, 0.12);
}

.stepwizard a {
  color: #5c78a1;
  display: inline-block;
  text-decoration: none;
  padding: 15px 20px;
  font-weight: 700;
}

.stepwizard a.active {
  background: #fff;
  display: inline-block;
  border-radius: 30px;
  box-shadow: 1px 1px 2px #b3b0b0;
  color: #2f5083;
}

.stepwizard-row {
  display: flex;
}

.stepwizard-row .stepwizard-step {
  margin-right: 20px;
}

span.step-wizard-number {
  background: #37659b;
  margin-right: 2px;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  font-size: 12px;
  display: inline-block;
  text-align: center;
}

.step-content {
  padding: 5rem 0 4rem;
}

.text-gray {
  color: #777;
}

@media (max-width: 640px) {
  .stepwizard-row {
    display: inherit;
    text-align: center;
  }
  .stepwizard-step {
    text-align: center;
    display: inline-block;
  }
}

@media (max-width: 640px) {
  .stepwizard a {
    padding: 7px 15px;
    font-size: 12px;
  }
}

.color-size-wrp {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-top: 20px;
}

.order-summary .discount-code {
  margin-bottom: 40px;
}

.order-detail .table tr td {
  vertical-align: middle;
  border-top: none;
}

.order-detail .table tr {
  border-bottom: 1px solid #eee;
}

.order-summary {
  background: #fff;
  padding: 20px;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.order-summary .table tr td {
  border-top: none;
  vertical-align: middle;
}

.order-complete {
  text-align: center;
}

.order-complete .success {
  font-size: 70px;
  color: #57cb03;
}

.order-detail-list {
  margin: 0;
  padding: 0;
}

.order-detail-list li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.order-detail-list li:last-child {
  border-bottom: none;
}

@media (max-width: 767px) {
  .order-detail-list {
    margin-bottom: 20px;
  }
  .order-detail-list .col-xs-4 {
    margin-top: 10px;
    width: 55%;
  }
  .order-detail-list .col-xs-3 {
    margin-top: 10px;
    width: 25%;
  }
  .order-detail-list .col-xs-1 {
    margin-top: 10px;
    width: 10%;
  }
}

.form-modal .modal-header {
  display: block;
  border-bottom: none;
}

.forgot-password {
  display: block;
  text-align: right;
}

.forgot-password a {
  color: #999;
}

.btn-facebook {
  background: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  background: #365598;
  color: #fff;
}

.btn-google:hover {
  color: #444;
  background: #f9f9f9;
}

.btn-google i,
.btn-facebook i {
  margin-right: 5px;
}

span.or {
  position: absolute;
  right: -10px;
  color: #999;
  top: 27%;
}

.form-modal .modal-md {
  max-width: 600px;
}

.form-group .fa-asterisk {
  font-size: 7px;
  color: #ff210d;
  position: relative;
  top: -5px;
}

.payment-option > h6 {
  margin-bottom: 30px;
  text-align: center;
}

.p-method-box {
  display: block;
  padding: 30px 15px;
  text-align: center;
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  border-radius: 5px;
  min-height: 197px;
}

.p-method-box:hover {
  text-decoration: none;
}

@media (max-width: 640px) {
  .p-method-box {
    margin-bottom: 20px;
  }
}

.insufficient-fund {
  text-align: center;
}

.pin-modal {
  padding: 25px;
  text-align: center;
}

.pin-field {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  margin-bottom: 30px;
}

.pin-field .form-control {
  margin: 0 5px;
}

@media (max-width: 640px) {
  .discount-code {
    text-align: center;
  }
  .discount-code .btn {
    margin-top: 15px;
    margin-left: 0 !important;
  }
}

.page-noscroll {
  overflow: hidden;
}

.page-noscroll .main-wrapper .hero-banner {
  min-height: 100vh;
}

.form-modal.form-box {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}

.form-modal .btn {
  padding: 10px;
  text-transform: none;
  text-transform: uppercase;
}

.form-modal input.form-modal select {
  height: 45px;
}

.form-modal select {
  width: 10%;
  font-size: 14px;
}

.form-modal input[type="checkbox"] {
  height: 16px;
}

.form-modal a.text-danger {
  text-decoration: underline;
}

.form-modal .or {
  display: inline-block;
  padding: 0 20px;
}

.form-modal .btn-facebook {
  text-transform: none;
}

.form-modal .btn-google {
  text-transform: none;
  color: #444;
  margin-top: 20px;
}

.page-register .form-modal.form-box {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .form-modal.form-box {
    width: auto;
    padding: 10px 10px 20px;
    margin-top: 0;
  }
  .form-modal .or {
    display: none;
  }
  .page-noscroll.page-register {
    overflow-y: auto;
  }
}

.account-box {
  position: relative;
  min-height: 80vh;
}

.account-box .account-header {
  padding: 15px 0 0;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
  z-index: 10;
  left: 0;
  right: 0;
  background: #fff;
}

.account-box .account-header .container {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.account-box .account-header .profile-pic {
  width: 65px;
  height: 65px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #000;
}

.account-box .account-header .balance-box {
  margin-left: auto;
}

.account-box .account-header .balance-box h5 {
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0;
}

.account-box .account-header .balance-box p {
  font-size: 13px;
  margin-bottom: 0;
  color: #777;
}

.account-box .account-header .account-menu h4 {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 5px;
}

.account-box .account-header .account-menu .nav .nav-item {
  margin-right: 30px;
}

.account-box .account-header .account-menu .nav .nav-item:last-child {
  margin-right: 0;
}

.account-box .account-header .account-menu .nav .nav-item .nav-link {
  padding: 0 0 10px;
  font-size: 1.1rem;
  color: #002d36;
  font-weight: 600;
}

.account-box .account-header .account-menu .nav .nav-item .nav-link.active,
a.router-link-exact-active {
  border-bottom: 3px solid #ff900a;
  color: #ff900a;
}

.account-box .my-account-content {
  padding-bottom: 0px;
}

.account-box .my-account-content.active {
  padding-bottom: 20px;
  padding-top: 30px;
}

.account-box .alert-kyc-form {
  background-color: #ff900a;
  border: 0;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.account-box .alert-kyc-form p {
  color: #fff;
  margin-bottom: 0;
}

.account-box .alert-kyc-form strong {
  display: block;
}

.account-box .alert-kyc-form .btn-default {
  background-color: #fff;
  color: #002d36;
  margin-left: auto;
}

.page-account-detail .navbar-fixed-top.nav-on-scroll {
  background-color: #fff;
}

.page-account-detail .navbar {
  background-color: #37659b;
}

.page-account-detail .my-account-content .box-top-row .card {
  text-align: center;
  border: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.page-account-detail .my-account-content .box-top-row .card:hover {
  text-decoration: none;
}

.page-account-detail .my-account-content .box-top-row .card:hover h4 {
  color: #ff900a;
}

.page-account-detail .my-account-content .box-top-row .card .card-body {
  padding: 3rem 0 1rem;
}

.page-account-detail .my-account-content .box-top-row .card .card-body img {
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.page-account-detail .my-account-content .box-second-row .card .card-footer,
.page-account-detail .my-account-content .box-top-row .card .card-footer {
  background-color: #fff;
  border: 0;
  padding-top: 0;
  padding-bottom: 2rem;
}

.page-account-detail .my-account-content .box-top-row .card .card-footer h4 {
  font-size: 1rem;
  font-weight: 600;
}

.page-account-detail .my-account-content .box-second-row {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3rem 0 0;
}

.page-account-detail .my-account-content .box-second-row .card {
  text-align: center;
  border: 0;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 3rem;
}

.page-account-detail .my-account-content .box-second-row .card:hover {
  text-decoration: none;
}

.page-account-detail .my-account-content .box-second-row .card:hover h4 {
  color: #ff900a;
}

.page-account-detail .my-account-content .box-second-row .card .card-body {
  padding-top: 0;
}

.page-account-detail .my-account-content .box-second-row .card .card-footer {
  padding-bottom: 0;
}

.page-account-detail .my-account-content .box-second-row .card .card-footer h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}

.page-account-detail
  .my-account-content
  .box-second-row
  .card
  .card-body
  .icon
  img {
  max-width: 80px;
  height: 80px;
  margin: 0 auto;
}

.page-account-detail .my-account-content .box-second-row .card .card-body img {
  max-width: 50px;
  max-height: 50px;
  margin: 0 auto;
}

.page-account-detail .my-account-content .ad-block {
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.page-account-detail .wallet-form-box.card .row {
  display: flex;
  height: 100%;
}

.page-account-detail .wallet-form-box.card .card,
.page-account-detail .wallet-form-box.card {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
  border: 0;
  height: 100%;
}

.congratulation-box {
  text-align: center;
}

.page-account-detail .wallet-form-box.card .card {
  height: calc(100% - 40px);
}

.page-account-detail .wallet-form-box.card .card-body {
  position: relative;
}

.page-account-detail .wallet-form-box.card .card-body .btn-close {
  position: absolute;
  top: 7px;
  right: 5px;
  box-shadow: 0 0 transparent;
  color: #444;
  z-index: 8;
}

.page-account-detail .wallet-form-box.card .card-body h4 {
  font-weight: 700;
  margin-bottom: 25px;
}

.page-account-detail .wallet-form-box.card .card-body h5 {
  font-weight: 700;
  font-size: 1.1rem;
}

.page-account-detail .wallet-form-box .congrats-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
}

.page-account-detail .wallet-form-box .congrats-wrapper h4 {
  margin-top: 20px;
}

.page-account-detail .wallet-form-box .congrats-wrapper i.text-success {
  color: #57cb03 !important;
}

.page-account-detail .wallet-form-box .congrats-wrapper p {
  font-size: 14px;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #ff900a;
  border-color: #ff900a;
  opacity: 0.5;
}

.page-account-detail .history-box {
  min-height: 350px;
  overflow-y: auto;
}

.page-account-detail .history-box .table tr td {
  border: 0;
  text-align: center;
  padding: 7px 10px;
  font-size: 0.9rem;
}

.page-account-detail .history-box .table tr td a {
  color: #444;
  text-decoration: underline;
  display: block;
  text-align: left;
  padding-left: 15px;
}

.page-account-detail .history-box .table tr td a:hover {
  color: #ff900a;
}

.page-account-detail
  .history-box
  .table.table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.help-box .badge-danger {
  color: #fff;
  background-color: #ff900a;
  border-radius: 50px;
  padding: 3px 8px;
}

.modal .table {
  width: 100%;
}

.modal-footer {
  text-align: center;
  border: 0;
  flex-direction: column;
}

.btn-text {
  border: 0;
  box-shadow: 0 0 transparent;
  margin-top: 10px;
  text-transform: none;
}

.table-no-border tr td {
  border: 0;
}

.pin-input {
  display: flex;
}

.pin-input .form-control {
  margin: 0 3px;
}

.pin-modal {
  padding: 20px;
}

.modal-pin-modal .modal-body h5 {
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 700;
}

.pin-modal .modal-body {
  padding: 30px 20px 20px;
}

.load-fund-modal h5 {
  margin-bottom: 15px;
}

.load-fund-modal .modal-body {
  padding: 30px 30px 20px;
}

.load-method-list {
  list-style-type: none;
  padding: 0;
}

.load-method-list li a {
  display: flex;
  padding: 7px 0;
  color: #444;
  line-height: 32px;
  border-bottom: none;
}

.load-method-list li a:hover {
  color: #ff900a;
  text-decoration: none;
}

.load-method-list li a figure {
  width: 46px;
  text-align: center;
  margin-bottom: 0;
  padding-right: 10px;
}

.page-account-detail .my-account-content .box-bank-list {
  background-color: #fff;
  padding: 3rem 0 0;
}

.page-account-detail .my-account-content .box-bank-list .card {
  text-align: center;
  border: 0;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 3rem;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  flex-direction: row;
}

.page-account-detail .my-account-content .box-bank-list .card:hover {
  text-decoration: none;
}

.page-account-detail .my-account-content .box-bank-list .card:hover h5 {
  color: #ff900a;
}

.page-account-detail .my-account-content .box-bank-list .card .card-body {
  padding: 0;
  display: block;
}

.page-account-detail .my-account-content .box-bank-list .card .card-footer {
  padding: 0;
  background-color: #fff;
  border: 0;
}

.page-account-detail .my-account-content .box-bank-list .card .card-footer h5 {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 20px;
  background-color: #fff;
}

.page-account-detail .my-account-content .box-bank-list .card .card-body img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.airlines-card .card-body img {
  height: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.airlines-card .card-footer {
  background: none;
}

.airlines-card .card-footer h5 {
  font-size: 1em !important;
  font-weight: normal !important;
  text-align: center;
}

.col-first {
  margin-right: 0;
  margin-left: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-image {
  background: #fff;
  height: inherit;
  width: inherit;
  text-align: center;
  padding: 4px;
}

.no-image i {
  border: 2px solid #ff900a;
  border-radius: 50%;
  padding: 12px;
  font-size: 2em;
  color: #ff900a;
}

.profile-pic {
  position: relative;
  width: 50%;
}

.img-profile {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.overlay-img {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@-webkit-keyframes overlaymove {
  0% {
    padding: 6px;
  }
  50% {
    padding: 12px;
  }
  100% {
    padding: 6px;
  }
}

@keyframes overlaymove {
  0% {
    padding: 6px;
  }
  50% {
    padding: 12px;
  }
  100% {
    padding: 6px;
  }
}

.profile-pic:hover .img-profile {
  opacity: 0.3;
}

.profile-pic:hover .overlay-img {
  opacity: 1;
}

.overlay-text {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  cursor: pointer;
}

.overlay-text i {
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 10px;
  font-size: 1.5em;
  -webkit-animation: overlaymove 3s infinite;
  animation: overlaymove 3s infinite;
}

@media (max-width: 768px) {
  .page-account-detail .my-account-content .box-top-row .card {
    margin-bottom: 1rem;
  }
  .account-box .account-header .balance-box {
    display: block;
  }
  .account-box .account-header .account-menu .nav .nav-item {
    margin-right: 20px;
  }
  .account-box .account-header .profile-pic {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .my-account-content .container,
  .account-box .account-header .container {
    width: auto;
    max-width: 100%;
  }
  .account-box .my-account-content {
    padding-top: 3px;
  }
}

@media (max-width: 575px) {
  .col-second {
    margin-right: 0;
    margin-left: 20px;
  }
}

@media (max-width: 420px) {
  .account-box .account-header .account-menu h4 {
    display: none;
  }
  .account-box .account-header .profile-pic {
    display: none;
  }
  .account-box .account-header .account-menu .nav .nav-item .nav-link {
    padding-bottom: 5px;
  }
  .account-box .account-header .balance-box h5 {
    margin-top: 5px;
  }
  .account-box .account-header .balance-box {
    text-align: center;
    padding-bottom: 15px;
  }
}

.hide {
  display: none;
}
