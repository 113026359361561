.bid-list-container {
  max-height: 300px;
  min-height: 300px;
  overflow-y: scroll;
}
.bid-list-container::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.bid-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.bid-list-container::-webkit-scrollbar-thumb {
  background: #ff900a;
  border-radius: 5px;
}

.bid-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bid-list-card {
  min-height: 180px;
  border-radius: 5px;
}

.btn-bid {
  border-radius: 10px;
  padding: 0px;
  width: 45%;
  height: 28px;
}
